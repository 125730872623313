<template>
  <div>
    <template>
      <p class="text-h5 font-weight-medium chapter">
        {{ questions[0].name }}
      </p>
      <v-row class="pt-3 mb-3 black--text">
        <v-col
          cols="12"
          md="12"
          v-for="(question, index) in questions"
          :key="index"
        >
          <div class="pb-3">{{$t('string.question_prefix')}}{{ index + 1 }}<span v-if="$_getLocale() != 'zh'">.</span></div>
          <div class="font-weight-medium pa-2" v-html="question.question"></div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    questions: [],

    data: {
      lang: null,
      count: 10,
      key: "",
    },
  }),
  methods: {
    getQuestions() {
      this.data.key = this.$route.params.code;
      this.data.lang = this.$_getLocale();
      axios
        .post(this.$api.servers.question + "/questions", this.data)
        .then((response) => {
          this.questions = response.data;
        });
    },
    
  },
  mounted(){
 this.getQuestions();
  },
  beforeMount() {
    this.getQuestions();
  },
};
</script>

<style>
.chapter {
  color: black;
}
</style>